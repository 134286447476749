import React from "react";
import "./App.css";

function App() {
  return (
    <div className="hero min-h-screen bg-base-200">
      <div className="text-center hero-content">
        <div className="max-w-lg">
          <h1 className="mb-5 text-5xl font-bold">Echo Kilo Charlie LLC</h1>
          <p className="mb-5">Reimagining software products and consulting.</p>
          <a
            href="mailto:ekc-web@maxcantor.com?subject=web_inquiry"
            className="btn btn-primary"
          >
            contact
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
